import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—February 2009" />
	<NewsHeader />
    <h2>February 2009</h2>



	<h3 className="blogdate">
	Wednesday, February 04, 2009
	</h3>

	<h4 className="blogitemtitle">
	Crazy Short Notice BDay Tour
	</h4>
	   <div className="blogitembody">
	   <p>Hi New Zealand!  Hi!  I'm here.  Over here.</p>
		<p>
		So, I'm having a birthday soon, and to celebrate I'm going down to the top of the south island for a week.  Gonna play a few shows because I love doing that.  It's summer, and on birthdays one should do what one loves.  I'm calling it <span style={{fontStyle: "italic"}}>Jesse Rivest's Crazy Short Notice BDay Tour</span>.
		</p><p>
		One show that needs special mention is <span style={{fontWeight: "bold"}}>Feb 13 @ Yaza Cafe in Nelson</span>.  Please come, and please tell anyone that may be in the area to also come...  That will be a nice (slightly early) birthday prezzie for me!  See my <span style={{fontStyle: "italic"}}>Performances</span> section for more info, eh.
		</p><p>
		Chur!
		</p><p>
		Jesse
		</p>
	   </div>
	   <div className="blogitemfooter">
	   <p>posted by Jesse @ 12:25 +1300</p>
	   </div>





	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
